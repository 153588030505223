import Vue from 'vue'

Vue.component('quantity-selector', {
  props: {
    initial: {
      type: Number,
      default: NaN
    },
    min: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: NaN
    },
    resetValue: {
      type: Number,
      default: NaN
    },
    disabled: Boolean
  },
  data: function () {
    return {
      value: this.initial
    }
  },
  computed: {
    increasable () {
      return this.value < this.max || (!this.max && this.max !== 0)
    },
    decreasable () {
      return this.value > this.min
    }
  },
  watch: {
    resetValue (value) {
      if (typeof value === 'number' && value >= 0) this.value = value
    }
  },
  methods: {
    decrease () {
      this.setValue(--this.value, true)
    },
    increase () {
      this.setValue(++this.value)
    },
    onInputChange (event) {
      const value = parseInt(event.target.value)

      if (isNaN(value)) {
        return this.overrideInputValue(this.value)
      }

      this.setValue(value)
    },
    setValue (value, isDecrease = false) {
      const oldValue = this.value

      const newValue = Math.max(
        this.min,
        value
      )

      this.value = this.max
        ? Math.min(this.max, newValue)
        : newValue

      if (oldValue === this.value) {
        this.overrideInputValue(this.value)
      }

      this.$emit('change', this.value, isDecrease)
    },
    overrideInputValue (value) {
      this.$refs.input.value = value
    }
  },
  template: `
  <div class="quantity-selector">
    <button
      class="quantity-selector__action js-decrease-button"
      type="button"
      v-on:click="decrease"
      :disabled="!decreasable || disabled"
      aria-label="Reduce product quantity by one">
    </button>
    <input
      class="quantity-selector__field"
      type="number"
      :value="value"
      @change="onInputChange"
      :min="min"
      :max="max"
      disabled
      aria-label="Quantity"
      aria-live="polite"
      ref="input">
    <button
      class="quantity-selector__action quantity-selector__action--plus js-increase-button"
      type="button"
      v-on:click="increase"
      :disabled="!increasable || disabled"
      aria-label="Increase product quantity by one">
    </button>
  </div>
  `
})
